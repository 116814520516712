import { PublicClientApplication } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { msalAuth } from 'views/auth/effects/msalAuth.effect';

export function useMsalLogin(
  mobile: boolean,
  msalInstance: PublicClientApplication
) {
  const navigate = useNavigate();
  const scopes = ['user.read'];
  const login = async () => {
    localStorage.setItem('login', 'msal');
    if (mobile) {
      msalInstance.loginRedirect({ scopes });
    } else {
      const loginResponse = await msalInstance.loginPopup({ scopes });
      if (loginResponse && loginResponse.account) {
        localStorage.setItem(
          'user',
          JSON.stringify({ token: loginResponse.accessToken })
        );
        const { action, status } = await msalAuth();

        if (!action.payload?.token) {
          navigate('/aerodynamics');
          return;
        }

        if (status !== 'error') {
          navigate('auth/login');
        }
      }
    }
  };
  return login;
}
