import React from 'react';
import '../styles/GPULoaderUI.css'; // import your css styles
import logo from '../../assets/img/harley_logo.png';
import spinner from '../../assets/img/LoadingCircle.png';

const GPULoaderUI: React.FC = () => {
    return (
        <div className="loader-container">
            <img src={logo} alt="Title" className="title-image" />
            <img src={spinner} alt="Loading..." className="spinner" />
        </div>
    );
};

export default GPULoaderUI;