import { AFrame, Scene } from "aframe";
import { IMethodSystemAframe } from "lib/aframe/systems/method-system";

interface ISceneControlComponent {
    onTapPlaceEnd(): void;
    onRecenterStart(): void;
    onTapPlaceStart(): void;
    el: AFrame['AEntity'];
}

const SceneControlComponent = {
    name: "scene-controller",
    val: {
        init(this: ISceneControlComponent) {
            const bike = document.getElementById('bike');

            const scene = this.el.sceneEl as Scene & {
                systems: { "method-system": IMethodSystemAframe };
            };
            const methodSystem = scene.systems["method-system"];
            const callback = methodSystem.getCallback('recenterStarted');
            if (callback) {
                this.onRecenterStart = callback;
            }
            const callback2 = methodSystem.getCallback('tapPlaceStarted');
            if (callback2) {
                this.onTapPlaceStart = callback2;
            }

            const callback3 = methodSystem.getCallback('tapPlaceEnded');
            if (callback3) {
                this.onTapPlaceEnd = callback3;
            }
            
            this.el.addEventListener('place-complete', () => {
                this.onTapPlaceEnd();
                // remove tap place
                const ring = document.getElementById('ring') as AFrame['AEntity'];
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }

            });

            // custom place handling
            this.el.addEventListener('custom-place-complete', () => {
                this.onTapPlaceEnd();
                // remove tap place
                const ring = document.getElementById('ring') as AFrame['AEntity'];
                if (ring) {
                    ring.removeAttribute('tap-place-custom')
                    this.el.sceneEl?.removeChild(ring)
                }

            });

            this.el.addEventListener('custom-recenter', () => {
                // recenter experience first
                scene.emit('recenter');
                // more sophisticated approach below

                const holder = document.getElementById('holder') as AFrame['AEntity'];
                if (holder) {
                    holder.removeAttribute('xrextras-pinch-scale');
                };

                // custom recenter is done using tap place component
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-entity');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-custom', 'id: holder');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);   
                    this.onTapPlaceStart();
 
                }

            })
        },
    },
};
export { SceneControlComponent as SceneControl};