import { alpha, createTheme, darken, lighten, Theme } from '@mui/material';
import { DisplayEnum } from 'shared/enums/display.enum';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		rounded_contained__microsoft: true;
		rounded_contained__auth_success: true;
		rounded_contained__auth_back: true;
	}
}

declare module '@mui/material/Pagination' {
	interface PaginationPropsVariantOverrides {
		outlined_dark: true
	}
}

export const theme: Theme = createTheme({
	palette: {
		primary: {
			main: '#fff',
			dark: '#2F2D47',
			light: '#2C2A3D',
			contrastText: '#E08D9A',
		},
		secondary: {
			main: '#3E275A',
			dark: '#403F5E',
			light: '#4C4A59',
			contrastText: '#FE9936',
		},
		success: {
			main: '#13F094',
			dark: '#21A76F',
			contrastText: '#7A6CF4',
			light: '#C065B9',
		},
		info: {
			main: '#9945FF',
			contrastText: '#BDB6FF',
			light: '#21A76F',
			dark: '#303030',
		},
		text: {
			primary: '#1E1C30',
			secondary: alpha('#1E1C30', 0.7),
			disabled: alpha('#fff', 0.5),
		},
		background: {
			default: '#fff',
			// paper: '#2F2E46',
		},
		grey: {
			A100: '#3C3857',
			A200: '#4F4E5D',
			A400: '#62616F',
			A700: '#26243A',
		},
		error: {
			main: '#AA1D1D42',
			light: '#FD2C2C',
			contrastText: '#C065B9',
		},
		common: {
			white: alpha('#fff', 0.2),
			black: alpha('#000', 0.2),
		},
		warning: {
			main: '#FF6943',
			light: '#529FC6',
		},
	},
	spacing: 10,
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#9945FF',
					color: 'white',
					fontFamily: 'GilroyMedium',
					fontSize: 16,
					padding: '14px',
				},
				arrow: {
					color: '#9945FF',
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: {
						variant: 'rounded_contained__microsoft',
					},
					style: {
						borderRadius: 6,
						backgroundColor: 'rgba(0,123,255,1)',
						color: '#fff',
						fontSize: 16,
						fontFamily: 'GilroySemiBold',
						padding: '16px 44px',
						backdropFilter: 'blur(9px)',
						textTransform: 'none',
						':hover': {
							backgroundColor: 'rgba(0,123,255,0.7)',
						},
						'@media screen and (max-width: 767px)': {
							padding: '16px 20px',
						}
					},
				},
				{
					props: {
						variant: 'rounded_contained__auth_success',
					},
					style: {
						borderRadius: 6,
						background: '#25CCAC 0% 0% no-repeat padding-box',
						color: '#fff',
						fontSize: 16,
						fontFamily: 'GilroySemiBold',
						padding: '16px 44px',
						backdropFilter: 'blur(9px)',
						textTransform: 'none',
						':hover': {
							background: darken('#13F094', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__auth_back',
					},
					style: {
						borderRadius: 6,
						background: '#25CCAC 0% 0% no-repeat padding-box',
						color: '#fff',
						fontSize: 16,
						fontFamily: 'GilroySemiBold',
						padding: '16px 44px',
						backdropFilter: 'blur(9px)',
						textTransform: 'none',
						marginRight: '16px',
						':hover': {
							background: darken('#13F094', 0.2),
						},
					},
				},
			],
		},
		MuiPagination: {
			variants: [
				{
					props: {
						variant: 'outlined_dark',
					},
					style: {
						li: {
							background: 'rgba(255, 255, 255, .1)',
							boxShadow: '0px 12px 99px #00000029',
							border: '1px solid #FFFFFF57',
							borderRadius: '4px',
							marginLeft: '10px',
							minWidth: '40px',
							minHeight: '40px',
							display: 'flex',
							alignItems: 'center',

							button: {
								margin: 0,
								'&.Mui-selected': {
									background: 'rgba(255, 255, 255, .2)',
								},
							},
						},
					},
				},
			],
		},
	},
})
