import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHandlers } from './hooks';

export function SamlRedirection() {
  const location = useLocation();
  const { handleSubmit } = useHandlers();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const SAMLResponse = queryParams.get('SAMLResponse');
    handleSubmit(SAMLResponse);
  }, [location.search, handleSubmit]);
  return null;
}
