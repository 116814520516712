import React, { useState, useEffect } from 'react'
import * as AFRAME from 'aframe'
import { AFrameScene, DISABLE_IMAGE_TARGETS } from '../../lib/aframe-component'
import { SceneControl } from './scene-controller'
import { TapPlaceCustom } from './tap-place-custom'
import { MethodSystem } from 'lib/aframe/systems/method-system'
import { ModelController } from './model-manager'
import CirclePanel from 'lib/hdui/CirclePanel'
import { AeroTube } from './aero-tubes'
import { GpuLoader } from './gpu-loader'
import GPULoaderUI from 'lib/hdui/GPULoaderUI'




const AeroDemo = (() => {
    const [isSceneLoaded, setIsSceneLoaded] = useState(false);
    const [isVertexBike, setIsVertexBike] = useState(false);
    

    // newer state dont' touch
    const [isGpuLoaded, setIsGpuLoaded] = useState(false);
    const [isSceneActive, setIsSceneActive] = useState(false);
    const [isAerodynamics, setIsAerodynamics] = useState(false);
    const [isPlot, setIsPlot] = useState(false);
    const [riderType, setRiderType] = useState(1);
    const [bikeType, setBikeType] = useState(0);
    const [ventType, setVentType] = useState(0);



    const [isXrLoaded, setIsXrLoaded] = useState(false);
    const xrLoaded = () => {
        console.log('XR loaded');
        // setIsXrLoaded(true);
        // setIsSceneLoaded(true);
    }

    useEffect(() => {
        window.addEventListener('xrloaded', xrLoaded);
        return () => {
          window.removeEventListener('xrloaded', xrLoaded);
        };
    }, []);


  

    const ascene = document.getElementsByTagName('a-scene')[0];
    const onRecenterClick = () => {
        
        if(ascene) {
            // emit event to recenter camera
            const aframeScene = ascene as AFRAME.Scene;
            //aframeScene.emit('recenter');
            aframeScene.emit('custom-recenter')
        } else {
            const ascene = document.getElementsByTagName('a-scene')[0];
            const aframeScene = ascene as AFRAME.Scene;
            //aframeScene.emit('recenter');
            aframeScene.emit('custom-recenter')
        }
    };

    const onAerodynamicsClick = () => {
        const value = !isAerodynamics;
        setIsAerodynamics(value);
        if (ascene) {
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('aero-state', {value: value});
        } else {
            const ascene = document.getElementsByTagName('a-scene')[0];
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('aero-state', {value: value});
        }
    }

    const onPlotClick = () => {
        const value = !isPlot;
        setIsPlot(value);
        if (ascene) {
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('plot-display', {value: value});
        } else {
            const ascene = document.getElementsByTagName('a-scene')[0];
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('plot-display', {value: value});
        }
    };

    const onBikeSelected = (id: number) => {
        const type = id;
        setBikeType(type);
        if (ascene) {
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('toggle-bike', {value: type});
        } else {
            const ascene = document.getElementsByTagName('a-scene')[0];
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('toggle-bike', {value: type});
        }
    }

    const onRiderSelected = (id: number) => {
        setRiderType(id);
        if (ascene) {
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('rider-selected', {value: id });
        } else {
            const ascene = document.getElementsByTagName('a-scene')[0];
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('rider-selected', {value: id });
        }
    };

    const onVentSelected = (id: number) => {
        setVentType(id);
        if (ascene) {
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('vent-selected', {value: id });
        } else {
            const ascene = document.getElementsByTagName('a-scene')[0];
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('vent-selected', {value: id });
        }
    };

    const sceneLoaded = () => {
        setIsSceneLoaded(true);
    }

    const recenterStarted = () => {
        setIsSceneActive(false);
    }

    const tapPlaceStarted = () => {
        setIsSceneActive(false);
    }

    const tapPlaceEnded = () => {
        setIsSceneActive(true);
    }

    const gpuLoaded = () => {
        setIsGpuLoaded(true);
    };

    

    // these are callbacks passed to aframe using methodSystem
    // these are accesed in aframe using associated string keys  
    const systemFunctions = {
        'recenterStarted': recenterStarted,
        'tapPlaceStarted': tapPlaceStarted,
        'tapPlaceEnded': tapPlaceEnded,
        'sceneLoadEnd' : sceneLoaded,
        'gpuLoadEnd': gpuLoaded,
      };
    
    return(
        <React.Fragment>
            <CirclePanel 
                onRecenter={onRecenterClick} 
                onAeroClick={onAerodynamicsClick}
                onPlotClick={onPlotClick}
                onRiderSelected={onRiderSelected}
                onBikeSelected={onBikeSelected}
                onVentSelected={onVentSelected}
                isSceneActive={isSceneActive}
                aerodynamicState={isAerodynamics}   
                plotState={isPlot} 
                riderType={riderType}
                bikeId={bikeType}
                ventState={ventType}
            />
            
            {!isGpuLoaded && <GPULoaderUI />}
        
            
            <AFrameScene
                sceneHtml={require('./aero-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[SceneControl, TapPlaceCustom, AeroTube, ModelController, GpuLoader]}
                systems={[MethodSystem]}
                systemFunctions={systemFunctions}
            />
        </React.Fragment>)
})
export { AeroDemo }