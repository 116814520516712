import type { ReactElement } from 'react';
import { SharedContext } from './context';
import { useData } from './hooks';

type Props = {
  children: ReactElement;
};

export function SharedContextProvider(props: Props) {
  const { children } = props;
  const { value } = useData();

  return (
    <SharedContext.Provider value={value}>{children}</SharedContext.Provider>
  );
}
