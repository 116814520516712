import type { Actions, SharedState } from './typings';
import { storeUserProducer, logoutProducer } from './Actions';

export function sharedReducer(state: SharedState, action: Actions) {
  switch (action.type) {
    case 'shared/storeUser':
      return storeUserProducer(state, action.payload);
    case 'shared/logout':
      return logoutProducer(state);
    default:
      return { ...state };
  }
}
