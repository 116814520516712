import React from 'react';
import '../styles/RiderPanel.css';
import HeaderIcon from '../../assets/img/icons/aero/groupicon.png';
import MaleIcon from '../../assets/img/icons/aero/male2.png';
import FemaleIcon from '../../assets/img/icons/aero/female2.png';

interface RiderPanelProps {
    onManClick: () => void;
    onWomanClick: () => void;
    riderType: number;
}

const RiderPanel: React.FC<RiderPanelProps> = ({onManClick, onWomanClick, riderType}) => (
    <div className="rider-panel"> {/* or any other value that fits your needs */}
        <div className="rider-panel-header">
            <img src={HeaderIcon} alt="Header" />
            <p>CHANGE RIDER</p>
        </div>
        <div className="rider-panel-buttons">
            <button 
                className={`rider-icon-button ${riderType === 0 ? 'rider-selected' : ''}`}
                onClick={onManClick}>
                <img src={MaleIcon} alt="Icon 1" />
                <p>Man</p>
            </button>
            <button 
                className={`rider-icon-button ${riderType === 1 ? 'rider-selected' : ''}`}
                onClick={onWomanClick}>
                <img src={FemaleIcon} alt="Icon 2" />
                <p>Woman</p>
            </button>
        </div>
    </div>
);

export default RiderPanel;