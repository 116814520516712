// src/components/QuizComponent.tsx
import React, { useState, useEffect } from 'react';
import HintCarousel from './hint-carousel';
import HintButton from './hint-button';

export interface CarouselItem {
  path: string;
  alt: string;
}

interface TutorialProps {
  items: CarouselItem[];  
  isActiveHomePanel: boolean;
}

const TutorialComponent: React.FC<TutorialProps> = ({ items, isActiveHomePanel }) => {
  const [showHint, setShowHint] = useState(true);
  const [orderedHintItems, setOrderedHintItems] = useState(items);
  const [showItemIndex, setShowItemIndex] = useState(0);

  const onShowHint = () => {
    setShowHint(true);
  };

  const getLocalStorageShowedHintItems = (): CarouselItem[] =>
    JSON.parse(localStorage.getItem('showedHintItems') ?? '[]');

  const getDiff = (): CarouselItem[] => {
    const showedHintItems = getLocalStorageShowedHintItems();
    const diff = items.filter(
      (item) =>
        !showedHintItems.some((elem: CarouselItem) => elem.alt === item.alt)
    );
    return diff;
  };

  const closeHint = () => {
    const showedHintItems = getLocalStorageShowedHintItems();
    const diff = getDiff();
    localStorage.setItem(
      'showedHintItems',
      JSON.stringify(showedHintItems.concat(diff))
    );
    setShowHint(false);
    setShowItemIndex(0);
  };

  useEffect(() => {
    const diff = getDiff();
    if (diff.length) {
      const newOrderHintItems = orderedHintItems
        .filter((item) => !diff.some((elem) => elem.alt === item.alt))
        .concat(diff);
      const showItemIndex = newOrderHintItems.indexOf(diff[0]);
      setShowItemIndex(showItemIndex === -1 ? 0 : showItemIndex);
      setOrderedHintItems(newOrderHintItems);
      setShowHint(true);
    } else {
      setShowHint(false);
    }
  }, [items]);

  return (
    <>
      {isActiveHomePanel && (<HintButton showHintEvent={onShowHint} />)}
      {showHint && (
        <HintCarousel
          items={orderedHintItems}
          showItemIndex={showItemIndex}
          closeHintEvent={closeHint}
        />
      )}
    </>
  );
};

export default TutorialComponent;
