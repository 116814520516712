import {AFrame, Scene} from "aframe";
import * as THREE from "three";
import { IMethodSystemAframe } from "lib/aframe/systems/method-system";

interface IGpuLoader {
    el: AFrame['AEntity'];
    gpuLoaded(): void;
    cameraPos: THREE.Vector3;
    cameraDir: THREE.Vector3;
};

const GpuLoaderComponent = {
    name: "gpu-loader",
    val: {
        init(this: IGpuLoader) {
            let isGPULoaded = false;
            const holder = document.getElementById('holder') as AFrame['AEntity'];
            const maxNumberOfModels = 9;
            let modelsLoaded = 0;
            this.cameraPos = new THREE.Vector3();
            this.cameraDir = new THREE.Vector3();

            // obtain methods from method system
            const scene = this.el.sceneEl as Scene & {
                systems: { "method-system": IMethodSystemAframe };
            };
            const methodSystem = scene.systems["method-system"];
            const callback = methodSystem.getCallback('gpuLoadEnd');
            if (callback) {
                this.gpuLoaded = callback;
            }



            this.el.addEventListener('model-loaded', () => {
                if (!isGPULoaded) {
                    // position object in front of camera
                    // potential optimisation: do it once instead of doing it per object
                    // Position the holder 5 units in front of the camera
                   
                    

                    modelsLoaded++;
                    if (modelsLoaded >= maxNumberOfModels) {
                        isGPULoaded = true;
                        onGPULoaded();
                    }
                }
                
            });

            const onGPULoaded = () => {
                this.el.sceneEl?.camera.getWorldPosition(this.cameraPos);
                this.el.sceneEl?.camera.getWorldDirection(this.cameraDir); 
                // Multiply the camera's world direction by -5
                this.cameraDir.multiplyScalar(5); 
                // Add this to the camera's world position to compute the desired position
                let holderPosition = this.cameraPos.add(this.cameraDir); 
                if (holder) {
                    holder.object3D.position.copy(holderPosition);
                }

                setTimeout(() => {
                    setModels();
                    this.el.sceneEl?.emit('custom-recenter');
                }, 500);
                
                setTimeout(() => {
                    this.gpuLoaded();
                }, 1500);
            };

            const setModels = () => {
                const bike2 = document.getElementById('bike2') as AFrame['AEntity'];
                if (bike2) {
                    bike2.setAttribute('visible', false);
                }

                const pm = document.getElementById('plotMaleSG') as AFrame['AEntity'];
                if (pm) {
                    pm.setAttribute('visible', false);
                }

                const pf = document.getElementById('plotFemaleSG') as AFrame['AEntity'];
                if (pf) {
                    pf.setAttribute('visible', false);
                }

                const male = document.getElementById('maleBiker') as AFrame['AEntity'];
                if (male) {
                    male.setAttribute('visible', false);
                }

                const smoke = document.getElementById('smokeColor') as AFrame['AEntity'];
                if (smoke) {
                    smoke.setAttribute('visible', false);
                }

                const smokeRG = document.getElementById('smokeColorRG') as AFrame['AEntity'];
                if (smokeRG) {
                    smokeRG.setAttribute('visible', false);
                }


            };
        },
    },
};
export {GpuLoaderComponent as GpuLoader}