import free_exploration from '../../assets/tutorial/free_exploration.gif';
import place_model from '../../assets/tutorial/place_model.gif';
import recenter_model from '../../assets/tutorial/recenter_model.gif';
import rotate_model from '../../assets/tutorial/rotate_model.gif';
import scale_model from '../../assets/tutorial/scale_model.gif';
import main_menu from '../../assets/tutorial/main_menu.gif';
import scale_rotate from '../../assets/tutorial/scale_rotate.gif';

export const SCALE_ROTATE = { path: scale_rotate, alt: 'scale_rotate' };

export const PLACE_MODEL = { path: place_model, alt: 'place_model' };

export const SCALE_MODEL = { path: scale_model, alt: 'scale_model' };

export const ROTATE_MODEL = { path: rotate_model, alt: 'rotate_model' };

export const FREE_EXPLORATION = { path: free_exploration, alt: 'free_exploration' };

export const MAIN_MENU = { path: main_menu, alt: 'main_menu' };

export const RECENTER_MODEL = { path: recenter_model, alt: 'recenter_model' };




