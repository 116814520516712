
import { AFrame } from 'aframe';
import * as THREE from 'three';
import TapPlaceImg from '../../assets/img/rectangle-icon.png';

interface ITapPlaceAframeElement {
    cube: any;
    touched: boolean;
    touchStartY: number;
    touchStartX: number;
    el: AFrame['AEntity'];
    raycaster: THREE.Raycaster;
    ground: AFrame['AEntity'];
    threeCamera: THREE.Camera;
    rayOrigin: THREE.Vector2;
    cursorLocation: THREE.Vector3;
    data: {
      id: string;
    };
    onClickHandler(): void;
    onTouchEnd(event: any): void;
    onTouchStart(event: any): void;
  }

const TapPlaceCustomComponent = {
    name: 'tap-place-custom',
    val: {
        schema: {
          id: { type: 'string', default: '' },
        },
        init(this: ITapPlaceAframeElement) {
            this.raycaster = new THREE.Raycaster()
            const camera = document.getElementById('camera') as AFrame['AEntity'];
            this.threeCamera = camera.getObject3D('camera') as THREE.Camera;
            this.ground = document.getElementById('ground') as AFrame['AEntity'];
            // 2D coordinates of the raycast origin, in normalized device coordinates (NDC)---X and Y
            // components should be between -1 and 1.  Here we want the cursor in the center of the screen.
            this.rayOrigin = new THREE.Vector2(0, 0)
            this.cursorLocation = new THREE.Vector3(0, 0, 0)

            // object to allow for scaling and rotating
            this.cube = document.createElement('a-box');
            this.cube.setAttribute('position', '0 0 0');
            this.cube.setAttribute('rotation', '0 90 0');
            this.cube.setAttribute('scale', '4 1 8');
            this.cube.setAttribute('material', 'color: green; transparent: true; opacity: 0');
            this.cube.setAttribute('xrextras-pinch-scale', '');
            this.cube.setAttribute('xrextras-two-finger-rotate', '');
            this.el.appendChild(this.cube);

            // create a tap place plane
            const planeEl = document.createElement('a-plane');
            planeEl.setAttribute('scale', '1 1 1');
            planeEl.setAttribute('rotation', '-90 0 0');
            planeEl.setAttribute('visible', 'false');
            planeEl.setAttribute('material', `src: url(${TapPlaceImg}); color: #fa6600; transparent: true; opacity: 1;`);
            const img = new Image();
            img.src = TapPlaceImg;
            img.onload = () => {
              planeEl.setAttribute('visible', 'true');
            };
            this.cube.appendChild(planeEl);

            //minimise object when starting tap-place session
            const objectToPlace = document.getElementById(this.data.id) as AFrame['AEntity'] | null;
            if (objectToPlace) {
              objectToPlace.setAttribute('scale', '0.001 0.001 0.001');
            }
    
            // this.el.sceneEl?.addEventListener('click', this.onClickHandler,{ once: true } as any);
            // instead of using above option to determine a tap,
            // using more robust touchstart and touchend events to ignore drags  

            this.touchStartX = 0;
            this.touchStartY = 0;
            this.touched = false;

            // Bind `this` to methods
            this.onClickHandler = this.onClickHandler.bind(this);
            this.onTouchStart = this.onTouchStart.bind(this);
            this.onTouchEnd = this.onTouchEnd.bind(this);

            this.el.sceneEl?.addEventListener('touchstart', this.onTouchStart, false);
            this.el.sceneEl?.addEventListener('touchend', this.onTouchEnd, false);

        },
        onClickHandler(this: ITapPlaceAframeElement) {
            // helper function
            const generateAnimationString = (fromScale: string, toScale: string, duration: number) => {
              return `property: scale; from: ${fromScale}; to: ${toScale}; dur: ${duration}; easing: easeOutElastic`;
            }

            const objectToPlace = document.getElementById(this.data.id) as AFrame['AEntity'] | null;
            if (objectToPlace) {
    
              
              // setting rotation
              const cubeRotation = this.cube.getAttribute('rotation');
              objectToPlace.setAttribute('rotation', {
                  x: 0,
                  y: cubeRotation.y,
                  z: 0
              });
              //animating scale
              const fromScale = '0.001 0.001 0.001';
              const toScale2 = this.cube.getAttribute('scale');
              const scale = toScale2.x * 0.85;
              // placing model at location of the cursor
              const position = this.el.object3D.position;
              const offset = new THREE.Vector3(0.3, 0, 0);
              position.add(offset);
              objectToPlace.setAttribute('position', position);

              const toScale = `${scale} ${scale} ${scale}`
              const duration = 350; // animation duration in milliseconds
              const animationString = generateAnimationString(fromScale, toScale, duration);
              objectToPlace.setAttribute('animation', animationString);
              objectToPlace.setAttribute('visible', 'true');
              objectToPlace.addEventListener('animationcomplete', () => {
                objectToPlace.removeAttribute('animation');
             
                
                // setting particle size depending on scale
                const holder = document.getElementById('holder') as AFrame['AEntity'];
                if (holder) {
                  
                    holder.setAttribute('xrextras-pinch-scale', `min: 0.33; max: 2; scale: 0`);
                    
                };
              });
    
              this.el.sceneEl?.emit('custom-place-complete'); // start lesson experience
            } else {
              console.warn('Could not find element with ID: ', this.data.id, ' to place');
            }

            
        },
        onTouchStart(this: ITapPlaceAframeElement, event: any) {
            this.touchStartX = event.changedTouches[0].clientX;
            this.touchStartY = event.changedTouches[0].clientY;
            this.touched = true;
        },
        onTouchEnd(this: ITapPlaceAframeElement, event: any) {
            const touchEndX = event.changedTouches[0].clientX;
            const touchEndY = event.changedTouches[0].clientY;
            const dx = touchEndX - this.touchStartX;
            const dy = touchEndY - this.touchStartY;
            if (Math.abs(dx) < 5 && Math.abs(dy) < 5) {
                // It's a tap!
                this.onClickHandler();
            }
            this.touched = false;
        },
        tick(this: ITapPlaceAframeElement) {
            // Raycast from camera to 'ground'
            this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera)
            const intersects = this.raycaster.intersectObject(this.ground.object3D, true)
            if (intersects.length > 0) {
              const [intersect] = intersects
              this.cursorLocation = intersect.point
            }
            this.el.object3D.position.y = 0.1
            this.el.object3D.position.lerp(this.cursorLocation, 0.4)
            this.el.object3D.rotation.y = this.threeCamera.rotation.y
        },
        remove(this: ITapPlaceAframeElement) {
            this.el.sceneEl?.removeEventListener('touchstart', this.onTouchStart);
            this.el.sceneEl?.removeEventListener('touchend', this.onTouchEnd);
        },
    },
};
export { TapPlaceCustomComponent as TapPlaceCustom }