import type { LoginData } from '../typings';
import { User } from 'shared/typings/User';
import { StoreUser, storeUserActionCreator } from 'shared/state';
import { RequestService } from 'shared/services';
import { URLS } from 'shared/utils';

export async function loginEffect(
  body: LoginData
): Promise<{ status: 'success' | 'error'; action: StoreUser }> {
  const response = await RequestService.post<LoginData, User>(URLS.login, body);

  if (response.status === 200) {
    const result = response.data;
    localStorage.setItem('user', JSON.stringify(result));
    return { action: storeUserActionCreator(result), status: 'success' };
  }

  return { action: storeUserActionCreator(), status: 'error' };
}
