import * as Yup from 'yup';

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required, please fill in the information')
    .min(3, 'Value is too short, please enter minimum 3 characters')
    .max(255, 'Value is too long, maximum length allowed is 255 characters'),
  password: Yup.string()
    .required('This field is required, please fill in the information')
    .min(
      3,
      'Incorrect password. Please, try again or use forgot password button'
    )
    .max(
      255,
      'Incorrect password. Please, try again or use forgot password button'
    ),
});
