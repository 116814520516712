import './App.scss';
import { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Home } from './views/home/home';
import { AuthRoutes } from 'views/auth/AuthRoutes';
import { ThemeProvider } from '@emotion/react';
import { theme } from 'theme';
import { SharedContextProvider } from 'shared/context';
import { ProtectedRoute } from 'shared/components/ProtectedRoute';
import { AeroDemo } from 'views/mesh-line/a-frame';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SharedContextProvider>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <AuthRoutes />
            <Routes>
              <Route
                path='/aerodynamics'
                element={
                    <AeroDemo />
                }
              />
              <Route
                path='/'
                element={<Navigate to='/aerodynamics' replace />}
              />
              {/* <Route path='/' element={<Home />} /> */}
            </Routes>
          </Suspense>
        </Router>
      </SharedContextProvider>
    </ThemeProvider>
  );
}

export default App;
