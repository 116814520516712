import { useCallback } from 'react';
import type { LoginData } from '../../typings';
import { useNavigate } from 'react-router-dom';
import { User } from 'shared/typings/User';
import { StoreUser } from 'shared/state';
import { loginEffect } from 'views/auth/effects/login.effect';
import { useSharedContext } from 'shared/context';

export function useHandlers() {
  const [, dispatch] = useSharedContext();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: LoginData, setError: (value: boolean) => void) => {
      let mounted = true;
      try {
        setError(false);

        const { action, status } = await loginEffect(values);

        if (!mounted) return;

        if (status === 'success') {
          dispatch(action as StoreUser);

          localStorage.removeItem('email');

          navigate('/aerodynamics');
        } else setError(true);
      } catch (err) {
        setError(true);
      }

      return () => {
        mounted = false;
      };
    },
    [dispatch]
  );

  const handleMainClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return {
    handleSubmit,
    handleMainClick,
  };
}
