import * as AFrame from 'aframe'

interface IModelControl {
    el: AFrame.Entity;
}

const ModelControllerComponent = {
    name: "model-controller",
    val: {
        init(this: IModelControl) {
            const bike1 = document.getElementById('bike1') as AFrame.Entity;
            const bike2 = document.getElementById('bike2') as AFrame.Entity;

            const femaleBiker = document.getElementById('femaleBiker') as AFrame.Entity;
            const maleBiker = document.getElementById('maleBiker') as AFrame.Entity;
            const plotFemaleSg = document.getElementById('plotFemaleSG') as AFrame.Entity;
            const plotMaleSg = document.getElementById('plotMaleSG') as AFrame.Entity;

            let isFemale = true;
            let isPlotState = false;

  
            this.el.sceneEl?.addEventListener('rider-selected', (event) => {
                const e = event as CustomEvent;
                const riderId = e.detail.value;
                isFemale = riderId === 1;
                if (isPlotState) {
                    displayPlotBiker();
                } else {
                    displayNormalBiker();
                }
                
            });

            
            this.el.sceneEl?.addEventListener('vent-selected', (event) => {
                const e = event as CustomEvent;
                const ventId = e.detail.value;
  
                const airVent = bike1.object3D.getObjectByName('AirVentBike1');
                if (airVent) {
                    airVent.rotation.x = 0.51 - 0.5275 * ventId; // deduct aprrox 30 degrees for each vent pos
                }


                const airVent2 = bike2.object3D.getObjectByName('AirVentBike2');
                if (airVent2) {
                    airVent2.rotation.x = 1.57 - 0.5275 * ventId; // deduct aprrox 30 degrees for each vent pos
                }
                             
            });
            
            this.el.sceneEl?.addEventListener('plot-display', (event) => {
                const e = event as CustomEvent;
                isPlotState = e.detail.value;
                if (isPlotState) {
                    displayPlotBiker();
                } else {
                    displayNormalBiker();
                }
                
            });

            this.el.sceneEl?.addEventListener('toggle-bike', (event) => {
                const ce = event as CustomEvent;
                switchBike(ce.detail.value);
            });

            const switchBike = (type: number) => {
                if (type === 1) {
                    bike2.setAttribute('visible', 'true');
                    bike1.setAttribute('visible', 'false');
                } else {
                    bike2.setAttribute('visible', 'false');
                    bike1.setAttribute('visible', 'true');
                }
            };

            const displayPlotBiker = () => {
                disableAllBikers();
                if (isFemale && plotFemaleSg) {
                    plotFemaleSg.setAttribute('visible', 'true');
                }

                if (!isFemale && plotMaleSg) { 
                    plotMaleSg.setAttribute('visible', 'true');
                }
            };

            const displayNormalBiker = () => {
                disableAllBikers();
                if (isFemale && femaleBiker) {
                    femaleBiker.setAttribute('visible', 'true');

                    // const parts = document.getElementById('parts') as AFrame.Entity;
                    // parts.setAttribute('visible', 'true');
                }

                if (!isFemale && maleBiker) { 
                    maleBiker.setAttribute('visible', 'true');
                }
            };

            const disableAllBikers = () => {
                if (femaleBiker) {
                    femaleBiker.setAttribute('visible', 'false');
                }

                if (maleBiker) { 
                    maleBiker.setAttribute('visible', 'false');
                }

                if (plotFemaleSg) {
                    plotFemaleSg.setAttribute('visible', 'false');
                }

                if (plotMaleSg) {
                    plotMaleSg.setAttribute('visible', 'false');
                }
            }


        },
    },
};
export { ModelControllerComponent as ModelController }