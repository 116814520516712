import React from 'react';
import '../styles/hintButton.css';

import hintIcon from '../../assets/img/info.png';

interface HintButtonProps {
  showHintEvent: () => void;
}

const HintButton: React.FC<HintButtonProps> = ({ showHintEvent }) => {
  const showHint = () => {
    showHintEvent();
  };

  return (
    <div className='hintButtonContainer' onClick={showHint}>
      <img src={hintIcon} alt='Hint button' />
    </div>
  );
};

export default HintButton;
