import { AFrame } from 'aframe';
import * as THREE from 'three';

import smokeUrl from '../../assets/img/smoke-texture.png';

interface IAeroTubesAframe {
    ventHalf: THREE.Object3D;
    ventClosed: THREE.Object3D;
    ventOpen: THREE.Object3D;
    ventHalfRG: THREE.Object3D;
    ventClosedRG: THREE.Object3D;
    ventOpenRG: THREE.Object3D;
    aeroState: boolean;
    bikeId: number;
    el: AFrame['AEntity'];
    materials: THREE.MeshBasicMaterial[];
};

const AeroTubeComponent = {
    name: "aero-tubes",
    val: {
        init(this: IAeroTubesAframe) {
            this.materials = [];
            const aeroModels = document.getElementById('smokeColor') as AFrame['AEntity'];
            const aeroModelsRG = document.getElementById('smokeColorRG') as AFrame['AEntity'];
            this.aeroState = false;
            this.bikeId = 0;

            aeroModels.addEventListener('model-loaded', () => {

                this.ventOpen = aeroModels.object3D.getObjectByName('VentOpened') as THREE.Object3D;
                this.ventClosed = aeroModels.object3D.getObjectByName('VentClosed') as THREE.Object3D;
                this.ventHalf = aeroModels.object3D.getObjectByName('VentSemiClosed') as THREE.Object3D;
                this.ventOpen.visible = true;
                this.ventClosed.visible = false;
                this.ventHalf.visible = false;

                
                aeroModels.object3D.traverse((child) => {
                    const obj = child as THREE.Mesh;
                    if (obj.material) {
                        const mat = obj.material as THREE.MeshBasicMaterial;
                        mat.depthWrite = true;
                        mat.alphaTest = 0.5;
                        const offset = Math.random();
                        mat.userData.uniforms = { u_time: { value: 0 }, u_offset: { value: offset } };
                        
                        mat.onBeforeCompile = ( shader ) => {
                            shader.uniforms.u_time = mat.userData.uniforms.u_time;
                            shader.uniforms.u_offset = mat.userData.uniforms.u_offset;

                            shader.fragmentShader = 'uniform float u_time;\nuniform float u_offset;\n' + shader.fragmentShader;
                            shader.fragmentShader = shader.fragmentShader.replace(
                                '#include <map_fragment>',
                                `
                                vec2 animatedUv = vec2(vUv.x, vUv.y + u_time * 0.0012 + u_offset);
                                vec4 mapColor = texture2D( map, animatedUv );
                                diffuseColor *= mapColor;
                                `
                            );
                        };
                        
                        obj.material = mat;
                        obj.material.needsUpdate = true;

                        this.materials.push(mat);
                    }               
                });
            });

            aeroModelsRG.addEventListener('model-loaded', () => {
                
                // setup vetns
                this.ventOpenRG = aeroModelsRG.object3D.getObjectByName('Bike2VentOpened') as THREE.Object3D;
                this.ventClosedRG = aeroModelsRG.object3D.getObjectByName('Bike2VentClosed') as THREE.Object3D;
                this.ventHalfRG = aeroModelsRG.object3D.getObjectByName('Bike2VentSemiClosed') as THREE.Object3D;
                this.ventOpenRG.visible = true;
                this.ventClosedRG.visible = false;
                this.ventHalfRG.visible = false;
                
                aeroModelsRG.object3D.traverse((child) => {
                    const obj = child as THREE.Mesh;
                    if (obj.material) {
                        const mat = obj.material as THREE.MeshBasicMaterial;
                        mat.depthWrite = true;
                        mat.alphaTest = 0.5;
                        const offset = Math.random();
                        mat.userData.uniforms = { u_time: { value: 0 }, u_offset: { value: offset } };

                        mat.onBeforeCompile = ( shader ) => {
                            shader.uniforms.u_time = mat.userData.uniforms.u_time;
                            shader.uniforms.u_offset = mat.userData.uniforms.u_offset;

                            shader.fragmentShader = 'uniform float u_time;\nuniform float u_offset;\n' + shader.fragmentShader;
                            shader.fragmentShader = shader.fragmentShader.replace(
                                '#include <map_fragment>',
                                `
                                vec2 animatedUv = vec2(vUv.x, vUv.y + u_time * 0.0012 + u_offset);
                                vec4 mapColor = texture2D( map, animatedUv );
                                diffuseColor *= mapColor;
                                `
                            );
                        };
                        
                        obj.material = mat;
                        obj.material.needsUpdate = true;
                        this.materials.push(mat);
                    }               
                });
            });

            this.el.sceneEl?.addEventListener('aero-state', (event) => {
                const e = event as CustomEvent;
                const state = e.detail.value;
                this.aeroState = state;
                if (this.bikeId === 0) {
                    aeroModels.setAttribute('visible', this.aeroState); 
                } else {
                    aeroModelsRG.setAttribute('visible', this.aeroState);
                }

                
            });

            this.el.sceneEl?.addEventListener('vent-selected', (event) => {
                const e = event as CustomEvent;
                const ventId = e.detail.value;
                switch(ventId) {
                    case 0:
                        this.ventOpen.visible = true;
                        this.ventClosed.visible = false;
                        this.ventHalf.visible = false;
                        this.ventOpenRG.visible = true;
                        this.ventClosedRG.visible = false;
                        this.ventHalfRG.visible = false;
                        break;
                    case 1:
                        this.ventOpen.visible = false;
                        this.ventClosed.visible = false;
                        this.ventHalf.visible = true;
                        this.ventOpenRG.visible = false;
                        this.ventClosedRG.visible = false;
                        this.ventHalfRG.visible = true;
                        break;  
                    case 2:
                        this.ventOpen.visible = false;
                        this.ventClosed.visible = true;
                        this.ventHalf.visible = false;
                        this.ventOpenRG.visible = false;
                        this.ventClosedRG.visible = true;
                        this.ventHalfRG.visible = false;
                        break;      
                    default:
                        this.ventOpen.visible = true;
                        this.ventClosed.visible = false;
                        this.ventHalf.visible = false;
                        this.ventOpenRG.visible = true;
                        this.ventClosedRG.visible = false;
                        this.ventHalfRG.visible = false;
                        break;

                }
            });

            this.el.sceneEl?.addEventListener('toggle-bike', (event) => {
                const e = event as CustomEvent;
                this.bikeId = e.detail.value;
                if (this.aeroState) {
                    if (this.bikeId === 0) {
                        aeroModels.setAttribute('visible', true);
                        aeroModelsRG.setAttribute('visible', false);
                    } else {
                        aeroModels.setAttribute('visible', false);
                        aeroModelsRG.setAttribute('visible', true);
                    }
                }
                
            });

            
        },
        tick(this: IAeroTubesAframe, time: number, timeDelta: number) {
            for (let i = 0; i < this.materials.length; i++) {
                this.materials[i].userData.uniforms.u_time.value = time;
            }
        },
    },
};

export { AeroTubeComponent as AeroTube }