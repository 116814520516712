import { User } from 'shared/typings/User';
import { StoreUser, storeUserActionCreator } from 'shared/state';
import { RequestService } from 'shared/services';
import { URLS } from 'shared/utils';

export async function samlEffect(
  SAMLResponse: string
): Promise<{ status: 'success' | 'error'; action: StoreUser }> {
  const response = await RequestService.get<{[key: string]: string}, User>(URLS.samlResponse, {SAMLResponse});

  if (response.status === 200) {
    const result = response.data;
    localStorage.setItem('user', JSON.stringify(result));
    return { action: storeUserActionCreator(result), status: 'success' };
  }

  return { action: storeUserActionCreator(), status: 'error' };
}
