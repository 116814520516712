import React, { useState } from 'react';
import HomePanel from './HomePanel';
import RiderPanel from './RiderPanel';
import '../styles/CirclePanel.css';

import MenuIcon from '../../assets/img/icons/aero/Menu.png';
import CloseIcon from '../../assets/img/icons/aero/Close.png';
import ArrowIcon from '../../assets/img/icons/aero/back.png';
import BikePanel from './BikePanel';
import VentPanel from './VentPanel';

import TutorialComponent from 'lib/hdui/tutorial'
import {
    SCALE_ROTATE,
    PLACE_MODEL,
    SCALE_MODEL,
    ROTATE_MODEL,
    FREE_EXPLORATION,
    MAIN_MENU,
    RECENTER_MODEL
} from 'shared/constants/tutorial-items'

const tutorialItems = [
    SCALE_ROTATE, 
    PLACE_MODEL, 
    SCALE_MODEL, 
    ROTATE_MODEL, 
    FREE_EXPLORATION, 
    MAIN_MENU, 
    RECENTER_MODEL
];



enum Panel {
    Home,
    Rider,
    Bike,
    Vent
}

interface CirclePanelProps {
   onRecenter: () => void;
   onAeroClick: () => void;
   onPlotClick: () => void;
   onRiderSelected: (id: number) => void;
   onBikeSelected: (id: number) => void;
   onVentSelected: (id: number) => void;
   isSceneActive: boolean;
   aerodynamicState: boolean;
   plotState: boolean;
   riderType: number;
   bikeId: number;
   ventState: number;
}

const CirclePanel: React.FC<CirclePanelProps> = ({onRecenter, onAeroClick, onPlotClick, onRiderSelected, onBikeSelected, onVentSelected, isSceneActive, aerodynamicState, plotState, riderType, bikeId, ventState}) => {
    const [isActive, setIsActive] = useState(false);
    const [currentPanel, setCurrentPanel] = useState(Panel.Home);
  
    const activate = () => {
      setIsActive(true);
    };
  
    const deactivate = () => {
      setIsActive(false);
    };
    
    // main button
    const handleClick = () => {
        if (!isActive) {
            activate();
        } else if (currentPanel === Panel.Home) {
            deactivate();
        } else {
            handleBackClick();
        }
    };

    // recenter button
    const handleRecenterClick = () => {
        onRecenter();
        deactivate();
    };

    const handleAeroClick = () => {
        onAeroClick();
    };

    const handlePlotClick = () => {
        onPlotClick();
    };

    const handleRiderClick = () => {
        setCurrentPanel(Panel.Rider);
    };

    const handleBackClick = () => {
        setCurrentPanel(Panel.Home);
    };

    const handleBikeClick = () => {
        setCurrentPanel(Panel.Bike);
    };

    const handleVentClick = () => {
        setCurrentPanel(Panel.Vent);
    };

    // rider selection
    const onManSelected = () => {
        onRiderSelected(0);
    };

    const onWomanSelected = () => {
        onRiderSelected(1);  
    };

    //bike selection
    const onBikeSelect = (id: number) => {
        onBikeSelected(id);
    };

    // vent selection
    const openVent = () => {
        onVentSelected(0);
    };

    const closeVent = () => {
        onVentSelected(2);
    };

    const halfOpenVent = () => {
        onVentSelected(1);
    };

    return (
        <div>
            <button className="circle-button" onClick={handleClick}>
                <img src={isActive ? (currentPanel === Panel.Home ? CloseIcon : ArrowIcon) : MenuIcon}
                    className={`circle-button-icon ${isActive ? (currentPanel === Panel.Home ? 'close-icon' : 'arrow-icon') : 'menu-icon'}`}
                    alt="Menu" />
            </button>
            {isActive && ( currentPanel === Panel.Home ? 
            <HomePanel 
                onRecenterClick={handleRecenterClick}
                onAeroClick={handleAeroClick} 
                onPlotClick={handlePlotClick}
                onRiderClick={handleRiderClick}
                onBikeClick={handleBikeClick}
                onVentClick={handleVentClick}
                isSceneActive={isSceneActive}
                aerodynamicState={aerodynamicState}
                plotState={plotState}
                riderType={riderType}
                ventType={ventState}/>
            : currentPanel === Panel.Rider ?
            <RiderPanel onManClick={onManSelected} onWomanClick={onWomanSelected} riderType={riderType}/>
            : currentPanel === Panel.Bike ?
            <BikePanel onBikeSelected={onBikeSelect} bikeId={bikeId}/>
            :
            <VentPanel onOpenClick={openVent} onCloseClick={closeVent} onHalfClick={halfOpenVent} ventState={ventState}/>)}
            <TutorialComponent items={tutorialItems} isActiveHomePanel={isActive && currentPanel === Panel.Home}/>
        </div>
        );

  };
  
  export default CirclePanel;
