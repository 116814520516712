import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DisplayEnum } from 'shared/enums/display.enum';
import { LoginValidationSchema } from './validationSchema';
import { LoginForm } from './LoginForm';
import { useHandlers } from './hooks';

export function LoginPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const [error, setError] = useState(false);
  const { handleSubmit, handleMainClick } = useHandlers();
  const initialValues = {
    email: localStorage.getItem('email') || '',
    password: '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack overflow='hidden' height='100vh' direction='row'>
      <Stack
        width={mobile ? '100%' : 530}
        p={3}
        alignItems='center'
        justifyContent='center'
        spacing={3}
        bgcolor={'#000'}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values, setError)}
          validationSchema={LoginValidationSchema}
        >
          <LoginForm />
        </Formik>
      </Stack>
      {!mobile && <Stack width='100%' bgcolor={'#fff'}></Stack>}
    </Stack>
  );
}
