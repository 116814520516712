import { useMemo, useReducer } from 'react';
import { Actions, SharedState, initState, sharedReducer } from 'shared/state';
import { AsyncDispatch, wrapAsync } from 'shared/utils';

export function useData() {
  const [state, dispatch] = useReducer(sharedReducer, initState);

  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  const value: [SharedState, AsyncDispatch<Actions>] = [state, asyncDispatch];

  return {
    value,
  };
}
