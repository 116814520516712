export const API_URL = 'https://ar-dev-eus-api.azurewebsites.net/api';

export const msalConfigKeys = {
	clientId: "a37d4245-5280-45f0-8743-21abc7a1e78b",
	authority:
		"https://livewirepartnersb2c.b2clogin.com/livewirepartnersb2c.onmicrosoft.com/b2c_1_livewireacademy",
	redirectUri: 'https://localhost:3000/auth/login',
	postLogoutRedirectUri: 'https://localhost:3000/auth/login',
	navigateToLoginRequestUrl: true,
	knownAuthorities: ["https://livewirepartnersb2c.b2clogin.com"],
}
