import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { API_URL } from '../../config';
import { User } from 'shared/typings/User';
import { URLS } from 'shared/utils';
import { UrlsValues } from 'shared/typings/Urls';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== URLS.login && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        localStorage.removeItem('user');
        window.location.href = `${window.location.origin}/login`;
        // try {
        // 	const rs = await instance.post(URLS.refreshToken, {
        // 		refreshToken: '',
        // 	})
        //
        // 	if (rs.status !== 200) {
        // 		window.location.pathname = '/login'
        // 	} else {
        // 		const user = rs.data
        //
        // 		storeUserActionCreator(user)
        // 		localStorage.setItem('user', JSON.stringify(user))
        //
        // 		return instance(originalConfig)
        // 	}
        // } catch (_error) {
        // 	return Promise.reject(_error)
        // }
      }
    }
    return Promise.reject(err);
  }
);

export class RequestService {
  static get<B, R>(
    url: UrlsValues,
    params?: B,
    authorized?: boolean
  ): Promise<AxiosResponse<R>> {
    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    return authorized
      ? instance.get(`${url}`, {
          params,
          headers: { Authorization: `Bearer ${user.token}` },
        })
      : instance.get(`${url}`, { params });
  }

  static post<B, R>(
    url: UrlsValues,
    body: B,
    authorized?: boolean
  ): Promise<AxiosResponse<R>> {
    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    return authorized
      ? instance.post(`${url}`, body, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
      : instance.post(`${url}`, body);
  }

  static postBlob<B, R>(url: UrlsValues, body: B): Promise<AxiosResponse<R>> {
    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    return instance.post(`${url}`, body, {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${user.token}` },
    });
  }

  static remove(
    url: UrlsValues,
    data?: any,
    authorized?: boolean
  ): Promise<AxiosResponse> {
    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    return authorized
      ? instance.delete(`${url}`, {
          headers: { Authorization: `Bearer ${user.token}` },
          data,
        })
      : instance.delete(`${url}`, { data });
  }
}
