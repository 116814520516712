import { User } from 'shared/typings/User';
import type { SharedState } from '../../typings';

export const storeUserProducer = (
  state: SharedState,
  payload?: User
): SharedState => ({
  ...state,
  user: payload ? payload : null,
});
