import { RequestService } from 'shared/services';
import { StoreUser, storeUserActionCreator } from 'shared/state';
import { User } from 'shared/typings/User';
import { URLS } from 'shared/utils';

export async function msalAuth(): Promise<{
  status: 'success' | 'error';
  action: StoreUser;
}> {
  const response = await RequestService.get<null, User>(
    URLS.msalAuth,
    null,
    true
  );
  if (response.status === 200) {
    const result = response.data;
    localStorage.setItem('user', JSON.stringify(result));
    return { action: storeUserActionCreator(result), status: 'success' };
  }
  return { action: storeUserActionCreator(), status: 'error' };
}
