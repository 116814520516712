import React from 'react';
import '../styles/VentPanel.css';
import openIcon from '../../assets/img/icons/aero/VentOpen.png';
import closedIcon from '../../assets/img/icons/aero/VentClose.png';
import halfIcon from '../../assets/img/icons/aero/VentHalfOpen.png';

interface RiderPanelProps {
    onOpenClick: () => void;
    onCloseClick: () => void;
    onHalfClick: () => void;
    ventState: number;
}

const RiderPanel: React.FC<RiderPanelProps> = ({onOpenClick, onCloseClick, onHalfClick, ventState}) => (
    <div className="vent-panel"> {/* or any other value that fits your needs */}
        <div className="vent-panel-header">
            <p>VENT DOOR STATE</p>
        </div>
        <div className="vent-panel-buttons">
            <button 
                className={`vent-icon-button ${ventState === 0 ? 'vent-selected' : ''}`} 
                onClick={onOpenClick}>
                <img src={openIcon} alt="Icon 1" />
                <p>Vent door: Full Open</p>
            </button>
            <button 
                className={`vent-icon-button ${ventState === 1 ? 'vent-selected' : ''}`} 
                onClick={onHalfClick}>
                <img src={halfIcon} alt="Icon 2" />
                <p>Vent door: ½ Open</p>
            </button>
            <button 
                className={`vent-icon-button ${ventState === 2 ? 'vent-selected' : ''}`} 
                onClick={onCloseClick}>
                <img src={closedIcon} alt="Icon 2" />
                <p>Vent door: Closed</p>
            </button>
        </div>
    </div>
);

export default RiderPanel;