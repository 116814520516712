import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreUser } from 'shared/state';
import { useSharedContext } from 'shared/context';
import { samlEffect } from 'views/auth/effects/saml.effect';

export function useHandlers() {
  const [, dispatch] = useSharedContext();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: any) => {
      let mounted = true;
      try {
        const { action, status } = await samlEffect(values);

        if (!mounted) return;

        if (status === 'success') {
          dispatch(action as StoreUser);

          localStorage.removeItem('email');

          navigate('/aerodynamics');
        }
      } catch (err) {}

      return () => {
        mounted = false;
      };
    },
    [dispatch]
  );

  return {
    handleSubmit,
  };
}
