import {
  Box,
  Button,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Form } from 'formik';
import { TLoginForm } from './typings';
import { DisplayEnum } from 'shared/enums/display.enum';
import { CustomTextField } from 'shared/components/CustomTextField';
import { useData } from './hooks/useData';
import { IconImage } from 'shared/components/IconImage';
import { useNavigate } from 'react-router-dom';
import BackArrow from './../../../assets/arrow_back_white.svg'

export function LoginForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const { defaultEmail, disableEmail } = useData();
  const goToLogin = () => navigate('/auth/login');

  return (
    <Stack width='100%'>
      <Form>
        <Box position='relative' mt={3} pb={2}>
          <CustomTextField<TLoginForm>
            name='email'
            label='EMAIL'
            id='email'
            type='email'
            placeholder='Please enter your e-mail'
            disabled={disableEmail}
            defaultValue={defaultEmail}
          />
        </Box>
        <Box position='relative' mt={1} pb={2}>
          <CustomTextField<TLoginForm>
            id='password'
            name='password'
            label='PASSWORD'
            type='password'
            placeholder='Please enter your password '
          />
        </Box>
        {/* <Stack
          direction='row'
          mb={3}
          mt={mobile ? 2 : 1}
          justifyContent='space-between'
        >
          <ForgotPassword
            label={
              <Link href='/reset' underline='none'>
                <Typography
                  color='rgba(43, 43, 43, .7)'
                  fontFamily='GilroySemiBold'
                >
                  Forgot password?
                </Typography>
              </Link>
            }
          />
        </Stack> */}
        <Stack direction={'row'}>
          <Button variant='rounded_contained__auth_back' onClick={goToLogin}>
            <IconImage src={BackArrow} width='24px' height='24px'/>
          </Button>
          <Button
            variant='rounded_contained__auth_success'
            fullWidth
            type='submit'
          >
            SIGN IN
          </Button>
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          mt={3.5}
          justifyContent='center'
        >
          {/* <Typography
            fontSize={mobile ? 10 : 16}
            color='#313131'
            fontFamily='GilroySemiBold'
            variant='body1'
          >
            Don't have an account?
          </Typography> */}
          {/* <SignUp
            label={
              <Link href='/register' underline='none'>
                <Typography
                  color='#313131'
                  fontFamily='GilroySemiBold'
                  variant='body1'
                >
                  Sign Up
                </Typography>
              </Link>
            }
          /> */}
          {/* <Typography
            fontSize={mobile ? 10 : 16}
            color='#313131'
            fontFamily='GilroySemiBold'
            variant='body1'
          >
            for free.
          </Typography> */}
        </Stack>
      </Form>
    </Stack>
  );
}
