import React from 'react';
import '../styles/HomePanel.css';

import CenterIcon from '../../assets/img/icons/aero/center.png';
import AeroIcon from '../../assets/img/icons/aero/Aerodynamics.png';
import PressureIcon from '../../assets/img/icons/aero/pressure.png';
import WomanIcon from '../../assets/img/icons/aero/women.png';
import ManIcon from '../../assets/img/icons/aero/male.png';
import BikeIcon from '../../assets/img/icons/aero/motorcycle.png';
import OpenIcon from '../../assets/img/icons/aero/VentOpen.png';
import CloseIcon from '../../assets/img/icons/aero/VentClose.png';
import HalfIcon from '../../assets/img/icons/aero/VentHalfOpen.png';
import PlotOrangeIcon from '../../assets/img/icons/aero/pressure_orange.png';
import AeroOrangeIcon from '../../assets/img/icons/aero/Aerodynamics_orange.png';

interface HomePanelProps {
    onRecenterClick: () => void;
    onAeroClick: () => void;
    onPlotClick: () => void;
    onRiderClick: () => void;
    onBikeClick: () => void;
    onVentClick: () => void;
    isSceneActive: boolean;
    aerodynamicState: boolean;
    plotState: boolean;
    riderType: number;
    ventType: number;
}

const HomePanel: React.FC<HomePanelProps> = ({onRecenterClick, onAeroClick, onPlotClick, onRiderClick, onBikeClick, onVentClick, isSceneActive, aerodynamicState, plotState, riderType, ventType}) => {
    
    let ventIcon;
    let ventText;
    switch (ventType) {
        case 0:
            ventIcon = OpenIcon;
            ventText = "Full Open";
            break;
        case 1:
            ventIcon = HalfIcon;
            ventText = "½ Open";
            break;
        case 2:
            ventIcon = CloseIcon;
            ventText = "Closed";
            break;
        default:
            ventIcon = OpenIcon;
            ventText = "Full Open";
    }

    return (
        <div className="panel">
            <div className="panel-column">
                <div className="icon-container" onClick={onRecenterClick}>
                    <img src={CenterIcon} alt="Center" />
                    <p>Reposition</p>
                </div>
                <div className={`icon-container ${!isSceneActive ? "inactive" : ""}`} onClick={ isSceneActive ? onRiderClick : () => {}}>
                    <img src={riderType === 1 ? WomanIcon : ManIcon} alt="Rider" />
                    <p>Change rider</p>
                </div>
            </div>
            <div className="panel-column">
                <div className={`icon-container ${!isSceneActive ? "inactive" : ""}`} onClick={ isSceneActive ? onAeroClick : () => {}}>
                    <img src={aerodynamicState ? AeroOrangeIcon : AeroIcon} alt="Aerodynamics" />
                    <p>Aerodynamics</p>
                </div>
                <div className={`icon-container ${!isSceneActive ? "inactive" : ""}`} onClick={isSceneActive ? onBikeClick : () => {}}>
                    <img src={BikeIcon} alt="Motorcycle" />
                    <p>Change motorcycle</p>
                </div>
            </div>
            <div className="panel-column">
                <div className={`icon-container ${!isSceneActive ? "inactive" : ""}`}onClick={ isSceneActive ? onPlotClick : () => {}}>
                    <img src={plotState ? PlotOrangeIcon : PressureIcon} alt="Plot" />
                    <p>Wind pressure</p>
                </div>
                <div className={`vent-icon-container ${!isSceneActive ? "inactive" : ""}`} onClick={isSceneActive ? onVentClick : () => {}}>
                    <div className="vent-icon-container-inner">
                        <img src={ventIcon} alt="Vent door" />
                    </div>
                    <p><span>Vent door:</span> <span>{ventText}</span></p>
                </div>
            </div>
        </div>
    )
};

export default HomePanel;
