import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthWrapper } from './auth-wrapper/auth-wrapper';
import { LoginPage } from './login-page/login-page';
import { SamlRedirection } from './SamlRedirection/SamlRedirection';

export function AuthRoutes() {
  return (
    <Routes>
      <Route path='/auth' element={<Navigate to='/auth/login' replace />} />
      <Route path='/auth/login' element={<AuthWrapper />} />
      <Route path='/auth/login/form' element={<LoginPage />} />
      <Route path='/samlredirection' element={<SamlRedirection />} />
    </Routes>
  );
}
