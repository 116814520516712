import React from 'react';
import '../styles/BikePanel.css';
import HeaderIcon from '../../assets/img/icons/aero/motorcycle.png';

interface BikePanelProps {
    onBikeSelected: (id: number) => void;
    bikeId: number;
}

const BikePanel: React.FC<BikePanelProps> = ({onBikeSelected, bikeId}) => {

    const onStreetGlideSelected = () => {
        onBikeSelected(0);
    };

    const onRoadGlideSelected = () => {
        onBikeSelected(1);
    };

    return (
        <div className="bike-panel"> {/* or any other value that fits your needs */}
            <div className="bike-panel-header">
                <img src={HeaderIcon} alt="Header" />
                <p>CHANGE MOTORCYCLE</p>
            </div>
            <div className="bike-panel-buttons">
                <button 
                    className={`bike-icon-button ${bikeId === 0 ? 'bike-selected' : ''}`}
                    onClick={onStreetGlideSelected}>
                    <p>StreetGlide</p>
                </button>
                <button 
                    className={`bike-icon-button ${bikeId === 1 ? 'bike-selected' : ''}`}
                    onClick={onRoadGlideSelected}>
                    <p>RoadGlide</p>
                </button>
            </div>
        </div>
    );
};

export default BikePanel;