import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DisplayEnum } from 'shared/enums/display.enum';
import { useSamlLogin } from './hooks/useSamlLogin';
import { SSOTypeEnum } from '../enums/SSOType.enum';
import { useMsalInstance } from 'shared/hooks/useMsalInstance';
import { useMsalLogin } from 'shared/hooks/useMsalLogin';

export function AuthWrapper() {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const onLoginPage = () => navigate('/auth/login/form');
  const msalInstance = useMsalInstance();
  const msalLogin = useMsalLogin(mobile, msalInstance);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack overflow='hidden' height='100vh' direction='row'>
      <Stack
        width={mobile ? '100%' : 530}
        p={3}
        alignItems='center'
        justifyContent='center'
        spacing={3}
        bgcolor={'#000'}
      >
        {/*
          <Button
          variant='rounded_contained__microsoft'
          fullWidth
          onClick={useSamlLogin(SSOTypeEnum.Microsoft)}
        >
          I am Harley-Davidson Employee
        </Button>
        */}
        
        <Button
          variant='rounded_contained__microsoft'
          fullWidth
          onClick={onLoginPage}
        >
          User Login
        </Button>
      </Stack>
      {!mobile && <Stack width='100%' bgcolor={'#fff'}></Stack>}
    </Stack>
  );
}
